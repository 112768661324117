@media screen and (max-width: 767px){ /* Mobile */
    .vcon1{
        background: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url("img/vc-banner.jpeg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right;
    }
}

@media screen and (min-width: 768px){ /* Desktop */
    .vcon1{background: url("img/vc-banner.jpeg");background-size: cover;}
}

.vcon1 p{color: #fff;margin-top:15px;}
.vcon1 span{color:#00EF70;}
.vcon1 button{background: transparent linear-gradient(239deg, #0088FF 0%, #003EBA 100%) 0% 0% no-repeat padding-box;border-radius: 30px !important;padding: 15px 30px !important;margin-top: 30px;margin-bottom: 30px;}
.vcon1 button:focus{box-shadow: none;}
.vcon1-right ul{padding-inline-start:0px;}
.vcon1-right li{list-style: none;display: inline-block;padding: 0px 15px;color:#fff;}
.vcon1-right li img{width: 75px;border-radius: 50%;margin:0 auto;display: block;height:75px;}
.vcon1-right{padding: 150px 0;}
.vcon1-right h1{font-size: 30px;color: #fff;font-weight: 600;text-transform: uppercase;margin-bottom: 0px;}
.vcon1-right h4{font-size: 20px;color: #fff;font-weight: 600;text-transform: uppercase;}

.vcon2{background: #F6F6F6;padding: 50px 0;}
.vcon2 h2{font-size: 24px;color: #000;font-weight: 600;text-align: left;text-transform: uppercase;margin-bottom: 10px;}
.vcon2box{background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 3px 6px #00000029;border-radius: 5px;padding: 15px;margin-top:15px;}
.vcon2box a:hover{text-decoration:none;}
.vcon2box i{font-size: 12px;}
.vcon2box img{margin: 0 auto;display: block;width: 75px !important;height:75px;border-radius: 50%;}
.vcon2box h3{color: #000;font-size: 15px;text-align: center;font-weight: 600;margin-top: 15px;margin-bottom: 5px;text-transform: uppercase;}
.vcon2box p{color: #006BE4;font-size: 12px;text-align: center;margin-bottom: 0px;}

.vcon3{background: #F6F6F6;padding: 0 0 50px 0;}
.vcon3 h2{font-size: 24px;color: #000;font-weight: 600;text-align: left;text-transform: uppercase;margin-bottom: 10px;}
.vcon3box{background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 3px 6px #00000029;border-radius: 5px;padding-bottom: 10px;width:100%;float:left;margin-top:15px;}
.vcon3box a:hover{text-decoration:none;}
.vcon3box i{font-size: 12px;}
.vcon3box img{margin: 0 auto;display: block;width: 100%;}
.vcon3box h3{color: #000;font-size: 15px;text-align: left;font-weight: 600;margin-top: 15px;margin-bottom: 5px;text-transform: uppercase;padding: 0 15px;}
.vcon3box p{color: #757575;font-size: 12px;text-align: left;margin-bottom: 0px;padding: 0 15px;width:50%;float:left;}

/* .vcon4{background: #F6F6F6;padding: 0 0 50px 0;}
.vcon4 h2{font-size: 24px;color: #000;font-weight: 600;text-align: left;text-transform: uppercase;margin-bottom: 10px;}
.vcon4 button{margin-top: 20px;background: transparent linear-gradient(239deg, #0088FF 0%, #003EBA 100%) 0% 0% no-repeat padding-box;border-radius: 30px !important;padding: 10px 20px !important;} */

/* .vcon4box {background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 3px 6px #00000029;border-radius: 5px;padding: 15px;width:100%;float:left;margin-top:15px;}
.vcon4box a:hover{text-decoration:none;}
.vcon4box i{font-size: 12px;}
.vcon4box img{margin: 0 auto;display: block;width: 100%;border-radius: 50%;}
.vcon4box h3{color: #0064DD;font-size: 15px;text-align: left;font-weight: 600;margin-top: 0px;margin-bottom: 5px;text-transform: uppercase;padding-left:15px;}
.vcon4box p{color: #000000;font-size: 12px;text-align: left;margin-bottom: 0px;padding-left:15px;}
.vcon4box h6{text-align: center;background: #FFCC00;width: auto;border-radius: 10px;font-size: 12px;}
.vcon4box > div:first-child{width:50px;float: left;}
.vcon4box > div:last-child{width:calc(100% - 50px);float: left;} */

.vcon5{background: #F6F6F6;padding: 50px 0;text-align: center;}
.vcon5 h2{font-weight: bold;color: #000;font-size: 20px;margin-top: 0px;text-align: center;}
.vcon5 h4{font-weight: 600;color: #fff;font-size: 16px;text-align: center;background: #0072EB;width: 50px;height: 50px;margin: 0 auto;border-radius: 50%;padding: 15px 0;margin-bottom: 15px;margin-top: 15px;}
.vcon5 p{color:#000;font-size: 12px;margin-bottom: 0px;}

.vcon6{background: #FFF;padding: 30px 0;}
.vcon6 h4{font-weight: 600;color: #000;font-size: 16px;margin-top: 15px;text-align: center;}
.vcon6 p{color:#000;font-size: 12px;text-align: center;}

.vcon7{background: #F8F8F8;padding: 50px 0;}
.vcon7 h2{font-weight: bold;color: #000;font-size: 20px;margin-top: 0px;}
.vcon7 h4{font-weight: 600;color: #000;font-size: 16px;margin-top: 15px;}
.vcon7 p{color:#000;font-size: 12px;}
.vcon7 i{color: #007AF2;}

.vcon8{background: #FFF;padding: 50px 0;}
.vcon8 h2{font-weight: bold;color: #000;font-size: 20px;margin-top: 0px;}
.vcon8 h4{font-weight: 600;color: #000;font-size: 16px;margin-top: 15px;}
.vcon8 p{color:#000;font-size: 12px;}

.vcon9{background: #FFF;padding: 0 0 50px 0;}
.vcon9 h2{font-weight: bold;color: #000;font-size: 20px;margin-top: 0px;margin-bottom: 20px;}
.vcon9 h4{font-weight: 600;color: #000;font-size: 16px;margin-top: 0px;padding-left:15px;margin-bottom: 0px;}
.vcon9 p{color:#000;font-size: 12px;margin-bottom: 0px;}
.vcon9-box > div:nth-child(2) p{padding-left:15px;}
.vcon9-box{background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 3px 6px #00000029;padding: 15px;}
.vcon9-box > div:first-child{width:50px;float:left;}
.vcon9-box > div:nth-child(2){width:calc(100% - 50px);float:left;margin-bottom: 10px;}

.vcon10{background: #F8F8F8;padding: 50px 0 0 0;}
.vcon10 h2{font-weight: bold;color: #000;font-size: 20px;margin-top: 0px;}
.vcon10 h4{font-weight: 600;color: #000;font-size: 16px;margin-top: 15px;}
.vcon10 p{color:#000;font-size: 12px;margin-bottom: 40px;}

.vcon11{background: #F8F8F8;padding: 0 0 50px 0;}
.vcon11 h2{font-weight: bold;color: #000;font-size: 20px;margin-top: 0px;}
.vcon11 h4{font-weight: 600;color: #000;font-size: 16px;margin-top: 0px;}
.vcon11 p{color:#000;font-size: 12px;margin-bottom: 0px;}
.vcon11-box{background: #F8F8F8 0% 0% no-repeat padding-box;box-shadow: 0px 3px 6px #00000029;padding: 15px;}

.vcon12 button{margin: 0px;background: transparent linear-gradient(239deg, #0088FF 0%, #003EBA 100%) 0% 0% no-repeat padding-box;border-radius: 30px !important;padding: 15px 30px !important;}
.vcon12 h4{color: #fff;text-align: center;}
.vcon12 p{color: #fff;text-align: center;}

.vcon13{background: #F8F8F8;padding: 50px 0;}
.vcon13 p{font-size: 14px;}


.spec-slide .owl-stage-outer,.od .owl-stage-outer,.user-slide .owl-stage-outer{padding: 30px 0;}
#spec-slide .owl-prev,#od .owl-prev{background: transparent linear-gradient(239deg, #0088FF 0%, #003EBA 100%) 0% 0% no-repeat padding-box;border-radius: 5px !important;padding: 5px 10px !important;margin-top: 30px;margin-bottom: 30px;color:#fff;margin-right: 5px;}
#spec-slide .owl-next,#od .owl-next{background: transparent linear-gradient(239deg, #0088FF 0%, #003EBA 100%) 0% 0% no-repeat padding-box;border-radius: 5px !important;padding: 5px 10px !important;margin-top: 30px;margin-bottom: 30px;color:#fff;}
#spec-slide .owl-nav,#od .owl-nav{position: absolute;top: -90px;right: -15px;}
.user-slide .owl-dots{text-align: center;}
.user-slide .owl-dot span{width: 10px;height: 10px;background: rgba(0, 114, 235, 0.12);border-radius: 50%;display: block;margin: 0 5px;}
.user-slide .owl-dot.active span{background: rgba(0, 114, 235, 1);}

.owl-centered .owl-wrapper {
    display: table !important;
  }
  .owl-centered .owl-item {
    display: table-cell;
    float: none;
    vertical-align: middle;
  }
  .owl-centered .owl-item > div {
    text-align: center;
  }
