.hfeed-wrap{background: #F6F6F6;padding: 0px 0;}
.hfeed-wrap .container{background: #fff;}

.hfeed-top{padding: 30px 30px 30px 30px;}
.hfeed-top h5{color: #000;font-weight: 600;text-transform: capitalize;margin-bottom: 0px;text-align: center;margin-bottom: 5px;}
.hfeed-top p{color: #000;margin-bottom: 0px;text-align: center;}



.hfeed-cont button{margin:30px 0;color: #fff;background: transparent linear-gradient(239deg, #0088FF 0%, #003EBA 100%) 0% 0% no-repeat padding-box;border-radius: 30px !important;padding: 10px 30px !important;}
#hfeed{border-bottom:0px !important;}

.hfeed-loop{min-height: 500px;}
.hfeed-loop h3{color: #000000DE;font-size: 18px;margin-bottom: 20px;}
.hfeed-loop h6{color: #777777DE;font-size: 12px;margin-top: 15px;margin-bottom: 5px;}
.hfeed-loop p{color: #212121DE;font-size: 14px;}
.hfeed-loop{margin-bottom: 15px;}
.hfeed-cont{padding:0 50px;}

.hfeed-loop .tag{padding-inline-start:0px;}
.hfeed-loop .tag li{display: inline-block;color: #0064DD;border:1px solid #0064DD;list-style: none;margin-right:10px;margin-bottom: 10px;padding: 5px 10px;border-radius: 10px;}

.hfeed-loop ul.share {padding-inline-start:0px;margin-bottom: 0px;margin-top: 10px;}
.hfeed-loop .share li{display: inline-block;list-style: none;padding-right: 30px;color: #777777DE;}
.hfeed-loop .share li i{color: #0064DD;}
.blog-des-box{
	display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.hfeed-loop a{
	    color: #000000DE;
}
.hfeed-loop a:hover{
text-decoration:none;
}

.blog-image{
  height: 250px;
}
